// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cakes-jsx": () => import("./../../../src/pages/cakes.jsx" /* webpackChunkName: "component---src-pages-cakes-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-pooja-sweets-jsx": () => import("./../../../src/pages/pooja_sweets.jsx" /* webpackChunkName: "component---src-pages-pooja-sweets-jsx" */),
  "component---src-pages-treats-jsx": () => import("./../../../src/pages/treats.jsx" /* webpackChunkName: "component---src-pages-treats-jsx" */)
}

